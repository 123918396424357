import React, { Component } from 'react';
import {Link} from 'gatsby';
import {FaGithub,FaMediumM,FaLine,FaFacebook} from 'react-icons/fa';
import {IoMdCall} from 'react-icons/io';
import { GoMail } from 'react-icons/go';

class Footer extends Component {
    render() {
        return (
            <footer className="footer">
                <div className="footer-content">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                <div className="footer-menu">
                                    <Link to="/">หน้าแรก</Link>
                                    <Link to="/tutor">สอนเขียนโปรแกรม</Link>
                                    <Link to="/makeweb">ทำเว็บไซต์</Link>
                                    <Link to="/blog">บทความ</Link>
                                    <Link to="/about">เกี่ยวกับเรา</Link>
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                <div className="row">
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                        <div className="footer-conten-icon">
                                                <IoMdCall />&nbsp;080-6372314
                                            </div>
                                        </div>
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                    <div className="footer-conten-icon">
                                            <GoMail />&nbsp;admin@bugyourdream.com
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex">
                                    <div className="footer-conten-icon">
                                        <a href="https://github.com/sorakraikasemsuk" aria-label="Github"><FaGithub /></a>
                                    </div>
                                    <div className="footer-conten-icon ml-5 mr-5">
                                        <a href="https://medium.com/@peakung03" aria-label="Medium"><FaMediumM /></a>
                                    </div>
                                    <div className="footer-conten-icon">
                                        <a href="http://line.me/ti/p/~pae_sorakrai" aria-label="Line"><FaLine /></a>
                                    </div>
                                    <div className="footer-conten-icon ml-5">
                                        <a href="https://web.facebook.com/Bugyourdream-104181654483006/?__tn__=%2Cd%2CP-R&eid=ARCJic5KDD7MB9hB98diuj1JbuhfSTCsRkKMn3gh5qHNX81guFx4KCHzSihxcOE0cTjXqmiL-xudIy1J" aria-label="Facebook"><FaFacebook /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-coppy">
                    <div className="container d-flex justify-content-center align-items-center">
                        <div style={{color:'white'}}>
                        © Copyright 2020 All rights reserved by BugYourDream.
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;