import React, { Component } from 'react';
import { Link } from 'gatsby';
import {FaGithub,FaMediumM,FaLine,FaFacebook} from 'react-icons/fa';
import {AiOutlineMenu , AiOutlineClose} from 'react-icons/ai';
import {IoMdCall} from 'react-icons/io';
import {Helmet} from 'react-helmet';

class Header extends Component {
    constructor(props){
        super(props)
        this.state={
            menuMobile : false
        };
    }
    handleMenuMobile=()=>{
        var x = document.getElementById("menu-mobile");
        if (window.getComputedStyle(x).display === "none") {
            x.style.cssText  = "block !important";
            x.style.display = "block";
            this.setState(() => {
                return {menuMobile: true};
              });
        } else {
            x.style.cssText  = "none !important";
            x.style.display = "none";
            this.setState(() => {
                return {menuMobile: false};
              });
        }
    }
    render() {
        return (
            <div>
                <Helmet>
                    <link rel="shortcut icon" type="image/png" href="/images/bug.png"/>
                    <meta name="Description" content="สอนเขียนโปรแกรมและรับทำเว็บไซต์ทุกรูปแบบ"></meta>
                    <html lang="en" />
                </Helmet>
                <div className="top-header">
                    <div className="container">
                        <div className="flex-grow-1">
                            <div className="d-flex justify-content-between p-1">
                                <div className="top-header-icon">
                                    <IoMdCall />&nbsp;080-6372314
                                </div>
                                <div className="top-header-icon">
                                    <div className="icon">
                                        <a href="https://github.com/sorakraikasemsuk" aria-label="Github"><FaGithub /></a>
                                    </div>
                                    <div className="icon">
                                        <a href="https://medium.com/@peakung03" aria-label="Medium"><FaMediumM /></a>
                                    </div>
                                    <div className="icon">
                                        <a href="http://line.me/ti/p/~pae_sorakrai" aria-label="Line"><FaLine /></a>
                                    </div>
                                    <div className="icon">
                                        <a href="https://web.facebook.com/Bugyourdream-104181654483006/?__tn__=%2Cd%2CP-R&eid=ARCJic5KDD7MB9hB98diuj1JbuhfSTCsRkKMn3gh5qHNX81guFx4KCHzSihxcOE0cTjXqmiL-xudIy1J" aria-label="Facebook"><FaFacebook /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <nav className="navbar navbar-light bg-light align-items-stretch">
                    <div className="container">
                        <div className="flex-grow-1">
                            <div className="d-flex">
                                <Link to="/">
                                    {/* <h2 className="font-color-base"><FaBug /> BugYourDream</h2> */}
                                    <img src="/images/logo.jpg" alt="logo" width="250" />
                                </Link>
                            </div>
                        </div>
                        <div className="flex-grow-1 header-menu">
                            <div className="d-flex h-100 justify-content-between">
                                <Link to="/" activeClassName="menuItem-active" className="menuItem"><p>หน้าแรก</p></Link>
                                <Link to="/tutor" activeClassName="menuItem-active" className="menuItem"><p>สอนเขียนโปรแกรม</p></Link>
                                <Link to="/makeweb" activeClassName="menuItem-active" className="menuItem"><p>ทำเว็บไซต์</p></Link>
                                <Link to="/blog" activeClassName="menuItem-active" className="menuItem"><p>บทความ</p></Link>
                                <Link to="/about" activeClassName="menuItem-active" className="menuItem"><p>เกี่ยวกับเรา</p></Link>
                                <Link to="/contactus" activeClassName="menuItem-active" className="menuItem"><p>ติดต่อเรา</p></Link>
                            </div>
                        </div>
                        <div className="flex-grow-1 header-menu-mobile text-right d-none">
                            {
                                this.state.menuMobile?<i className="menuItem" onClick={this.handleMenuMobile}><AiOutlineClose /></i>:
                                <i className="menuItem" onClick={this.handleMenuMobile}><AiOutlineMenu /></i>
                            }
                        </div>
                    </div>
                </nav>
                <div className="container" id="menu-mobile" style={{display:'none'}}>
                        <div className="row w-100">
                            <div className="col-12">
                            <Link to="/" activeClassName="menuItem-active" className="menuItem-mobile"><p>หน้าแรก</p></Link>
                                <Link to="/tutor" className="menuItem-mobile"><p>สอนเขียนโปรแกรม</p></Link>
                                <Link to="/makeweb" className="menuItem-mobile"><p>ทำเว็บไซต์</p></Link>
                                <Link to="/blog" className="menuItem-mobile"><p>บทความ</p></Link>
                                <Link to="/about" className="menuItem-mobile"><p>เกี่ยวกับเรา</p></Link>
                                <Link to="/contactus" className="menuItem-mobile"><p>ติดต่อเรา</p></Link>
                            </div>
                        </div>
                    </div>
            </div>
        );
    }
}

export default Header;